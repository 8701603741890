
import { computed, onMounted, onUpdated, ref } from "vue";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import { SPSelect } from "../../components/catalogs-select";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import moment from "moment";
import FileCalendar from "@/components/file-calendar/FileCalendar.vue";

export default {
  name: "Calendar",
  components: {
    SPSelect,
    FileCalendar,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const search = ref("");
    const loading = ref(false);
    const dataList = ref([]);
    const filtered = ref([]) as any;
    const code = ref("");
    const file_type_id = ref([]);
    const maritime_voyage_id = ref([]);
    const office_id = ref([]);
    const port_id = ref([]);
    const vessel_id = ref([]);
    const ship_consignee_id = ref([]);
    const customer_id = ref([]);
    const check_in_id = ref([]);
    const check_out_id = ref([]);
    const operation_id = ref([]);
    const created_date = ref([]);
    const arrive_date = ref([]) as any;
    const prefix = ref("");
    const calls = ref("");
    const originalData = ref([]);
    const year = ref(moment().format("YYYY"));

    const u = localStorage.getItem("user") as any;
    const user = {
      value: JSON.parse(u),
    };
    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };
    const canCreate = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "maritime files: create")
            .length > 0
        );
      }
      return false;
    });
    const canSeeDetails = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "maritime files: show")
            .length > 0
        );
      }
      return false;
    });
    const canEdit = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "maritime files: update")
            .length > 0
        );
      }
      return false;
    });

    const formatDate = (value) => {
      return moment(value).format("DD/MM/YYYY");
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("", [
        { route: "/calendar", label: "ifilecalendar" },
      ]);
      const date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth();
      let firstDay = new Date(y, m - 1, 15);
      let lastDay = new Date(y, m + 1, 15);
      arrive_date.value.push(moment(firstDay).format("YYYY-MM-DD hh:mm"));
      arrive_date.value.push(moment(lastDay).format("YYYY-MM-DD hh:mm"));
      serverSearch();
    });

    const serverSearch = () => {
      loading.value = true;
      store.commit("setLoadingStatus", true);
      ApiService.query(`/api/maritimefiles`, {
        params: {
          per_page: 0,
          in_calendar: true,
          code: code.value,
          maritime_voyage_id: maritime_voyage_id.value,
          file_type_id: file_type_id.value,
          office_id: office_id.value,
          port_id: port_id.value,
          vessel_id: vessel_id.value,
          ship_consignee_id: ship_consignee_id.value,
          customer_id: customer_id.value,
          check_in_id: check_in_id.value,
          check_out_id: check_out_id.value,
          operation_id: operation_id.value,
          prefix: prefix.value,
          calls: calls.value,
          from: created_date.value.length ? created_date.value[0] : "",
          to: created_date.value.length ? created_date.value[1] : "",
          arrive_from: arrive_date.value.length ? arrive_date.value[0] : "",
          arrive_to: arrive_date.value.length ? arrive_date.value[1] : "",
        },
      })
        .then(({ data }) => {
          originalData.value = data.maritime_files;
          filtered.value = createCalendarEvents(originalData.value);
          loading.value = false;
          store.commit("setLoadingStatus", false);
        })
        .catch(() => {
          store.commit("setLoadingStatus", false);
        });
    };

    const onSearch = () => {
      filtered.value = dataList.value.filter(
        (x) =>
          JSON.stringify(x)
            .toLowerCase()
            .indexOf(search.value.toLowerCase()) !== -1
      );
    };

    const createCalendarEvents = (data) => {
      let events = [] as any;
      data.forEach((item) => {
        let date = "";
        const port_location_id = item.port.location_id;
        const location_id = item.vessel_route.length
          ? item.vessel_route.filter((x) => x.id === port_location_id)
          : [];
        if (location_id.length) {
          console.log(
            item.ship_moorings.filter((x) => x.purpose_id === 1)[0].check_in
          );
          let flag = true;
          const purposes = item.ship_moorings.map((x) => x.purpose_id);
          if (purposes.includes(1)) {
            date = item.ship_moorings.filter((x) => x.purpose_id === 1)[0]
              .check_in;
            flag = false;
          }
          if (purposes.includes(3) && flag) {
            date = item.ship_moorings.filter((x) => x.purpose_id === 3)[0].check_in;
            flag = false;
          }
          if (purposes.includes(4) && flag) {
            date = item.ship_moorings.filter((x) => x.purpose_id === 4)[0].check_in;
          }
          console.log(moment(date).format("YYYY-MM-DD"));
          console.log(data);
          events.push({
            vessel: `${item.vessel.name}`,
            title: `${item.code}`,
            location: `${item.port.location.full_code}`,
            date: date !== "" ? moment(date).format("YYYY-MM-DD") : date,
            id: item.id,
            color: item.port.color !== null ? item.port.color : "#5F9EA0",
          });
        }
      });
      return events;
    };

    const createElement = () => {
      store.commit("setLoadingStatus", true);
      router.push({ name: "maritimefileCreate" });
    };

    onUpdated(() => {
      // console.clear();
    });

    return {
      canCreate,
      canEdit,
      canSeeDetails,
      permissions,
      loading,
      filtered,
      dataList,
      search,
      code,
      maritime_voyage_id,
      file_type_id,
      office_id,
      port_id,
      vessel_id,
      ship_consignee_id,
      customer_id,
      check_in_id,
      check_out_id,
      operation_id,
      prefix,
      calls,
      created_date,
      arrive_date,
      user,
      formatDate,
      onSearch,
      serverSearch,
      createElement,
    };
  },
};
