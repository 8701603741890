import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row px-2" }
const _hoisted_2 = { class: "row px-1" }
const _hoisted_3 = { class: "row px-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FullCalendar = _resolveComponent("FullCalendar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_FullCalendar, {
      class: "demo-app-calendar",
      options: _ctx.calendarOptions
    }, {
      eventContent: _withCtx((arg) => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("small", null, _toDisplayString(arg.event.extendedProps.vessel), 1)
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("small", null, _toDisplayString(arg.event.extendedProps.location), 1)
        ]),
        _createElementVNode("div", _hoisted_3, _toDisplayString(arg.event.title), 1)
      ]),
      _: 1
    }, 8, ["options"])
  ]))
}